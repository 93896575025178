/* eslint-disable no-bitwise */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import ToggleButton from '@mui/material/ToggleButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import TuneIcon from '@mui/icons-material/Tune';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ListItemIcon from '@mui/material/ListItemIcon';

// Us
import { CustomTheme } from '@components/layout/Theme';
import { navWorkspace } from '@components/utilities/Navigation';

const StyledToggleButton = styled(ToggleButton)(({ theme }: { theme: CustomTheme }) => ({
  '&': {
    ...theme.typography.button,
    textTransform: 'none',
    fontWeight: 'bold',
    height: '30px',
    alignSelf: 'center',
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.5),
    borderWidth: 0,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: theme.palette.action.hoverInverse,
    // borderRadius: '50%',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hoverInverse,
    // borderRadius: '50%',
  },
}));

export default function Settings() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(!anchorEl ? event.currentTarget : null);
  };

  const handleNavigate = (url: string, title: string) => {
    navWorkspace(history, url, title);
    setAnchorEl(null);
  };

  return (
    <>
      <StyledToggleButton value="depressed" selected={!!anchorEl} onClick={handleClick}>
        Settings
        {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </StyledToggleButton>
      <Menu
        id="SettingsMenu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {[
          { title: 'Configuration', url: '/admin/profiles.aspx', icon: <TuneIcon fontSize="small" /> },
          { title: 'User Administration', url: '/admin/users.aspx', icon: <ManageAccountsIcon fontSize="small" /> },
          { title: 'Installation Settings', url: '/admin/properties.aspx?npage=asp', icon: <InstallDesktopIcon fontSize="small" /> },
          { title: 'Troubleshooting', url: '/admin/diag.aspx', icon: <TroubleshootIcon fontSize="small" /> },
          { title: 'Metadata Administration', url: '/admin/p_admin.aspx', icon: <AdminPanelSettingsIcon fontSize="small" /> },
        ]
          .map(
            (child) => (
              <MenuItem key={child.title}>
                <ListItemButton onClick={() => handleNavigate(child.url, child.title)}>
                  <ListItemIcon>{child.icon}</ListItemIcon>
                  {child.title}
                </ListItemButton>
              </MenuItem>
            ),
          )}
      </Menu>
    </>
  );
}
