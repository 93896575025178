const isProduction = process.env.NODE_ENV === 'production';

interface ILogger {
  /**
   * Equivalent to console.debug().
   * @remarks Avoid passing multiple arguments to this function. Instead, use string interpolation. This avoids leaks because
   * the browser must keep a reference to all additional parameters.
   * @param args Normal arguments to any console logging function (debug/log/info/warn/error/trace).
   */
  debug: (...args: any[]) => void;
  /**
   * Traditionally console.log() but prefer info() instead.
   * @remarks Avoid passing multiple arguments to this function. Instead, use string interpolation. This avoids leaks because
   * the browser must keep a reference to all additional parameters.
   * @param args Normal arguments to any console logging function (log/info/warn/error/trace).
   */
  log: (...args: any[]) => void;
  /**
   * Equivalent to console.info().
   * @remarks Avoid passing multiple arguments to this function. Instead, use string interpolation. This avoids leaks because
   * the browser must keep a reference to all additional parameters.
   * @param args Normal arguments to any console logging function (log/info/warn/error/trace).
   */
  info: (...args: any[]) => void;
  /**
   * Equivalent to console.warn().
   * @remarks Avoid passing multiple arguments to this function. Instead, use string interpolation. This avoids leaks because
   * the browser must keep a reference to all additional parameters.
   * @param args Normal arguments to any console logging function (log/info/warn/error/trace).
   */
  warn: (...args: any[]) => void;
  /**
   * Equivalent to console.error().
   * @remarks Avoid passing multiple arguments to this function. Instead, use string interpolation. This avoids leaks because
   * the browser must keep a reference to all additional parameters.
   * @param args Normal arguments to any console logging function (log/info/warn/error/trace).
   */
  error: (...args: any[]) => void;
  /**
   * Equivalent to console.trace().
   * @remarks Avoid passing multiple arguments to this function. Instead, use string interpolation. This avoids leaks because
   * the browser must keep a reference to all additional parameters.
   * @param args Normal arguments to any console logging function (log/info/warn/error/trace).
   */
  trace: (...args: any[]) => void;
}

export function whFgRdBg(msg: string, ...args: any[]): any[] {
  return isProduction ? [''] : [`%c${msg}`, 'color: white; background-color: darkred; font-family: consolas, monospace', ...args];
}

export function gnFgGyBg(msg: string, ...args: any[]): any[] {
  return isProduction ? [''] : [`%c${msg}`, 'color: green; background-color: darkgrey; font-family: consolas, monospace'];
}

export function whFgGnBg(msg: string, ...args: any[]): any[] {
  return isProduction ? [''] : [`%c${msg}`, 'color: white; background-color: darkgreen; font-family: consolas, monospace'];
}

export const logger: ILogger = isProduction ? {
  debug: (...args: any[]) => { },
  log: (...args: any[]) => { },
  info: (...args: any[]) => { },
  warn: (...args: any[]) => { },
  error: (...args: any[]) => { },
  trace: (...args: any[]) => { },
} as ILogger : console as unknown as ILogger;
